import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRequestedPath } from '../../../../redux/leaseApp/lease-app-slice';
import { RootState } from '../../../../redux/types';
import { getText } from '../../../../utils/getter';
import PartnerLogo from '../../../b2b/components/partner-logo/partner-logo';
import RadioContainer from '../../../b2b/components/radio-container/radio-container';
import Radio from '../../../b2b/components/radio/radio';
import SubHeader from '../../sub-header/sub-header';
import LoginForm from '../login-form/login-form';
import RegisterForm from '../register-form/register-form';

interface RegisterState {
  requestedPath?: string;
}

interface Props {
  locationState?: RegisterState;
}

const Register: React.FC<Props> = ({
  locationState: { requestedPath = '/active-applications/overview' } = {},
}) => {
  // Lease Application Storage
  const { carData } = useSelector((state: RootState) => state.leaseApp);

  // Cache Store
  const { isSubheaderVisible } = useSelector((state: RootState) => state.cache);

  // Dispatch hook
  const dispatch = useDispatch();

  // Get current path
  const location = useLocation();

  // Navigation hook
  const navigate = useNavigate();

  // Switch between register and register values
  const [formTypeValue, setFormTypeValue] = useState<'LOGIN' | 'REGISTER'>(
    location.pathname === '/register' ? 'REGISTER' : 'LOGIN',
  );

  // No car data
  useEffect(() => {
    if (!carData && formTypeValue === 'REGISTER') {
      navigate('/no-offer');
    }
  }, [carData, formTypeValue, navigate]);

  // Dispatch requested path
  useEffect(() => {
    if (requestedPath) dispatch(setRequestedPath(requestedPath));
  }, [requestedPath, dispatch]);

  return (
    <>
      <div
        className={`header-view-status-container ${
          isSubheaderVisible ? 'active' : ''
        }`}
        style={{ paddingBottom: isSubheaderVisible ? '0px' : '16px' }}
      >
        <div className='header' data-testid='header'>
          <div role='presentation'>
            <PartnerLogo data-testid='header-logo' className='image' />
          </div>
        </div>
        <SubHeader showCancelButton={false} bounceArrow />
      </div>
      <div className={`auth-container ${carData ? 'top-136' : 'top-40'}`}>
        <div className='auth-form-container login'>
          <div className='auth-form-content login'>
            <h1 className='title bottom-24'>{getText('app_welcome_text')}</h1>
            <RadioContainer title='Ich habe ...'>
              <Radio
                checked={formTypeValue === 'LOGIN'}
                onChange={() => setFormTypeValue('LOGIN')}
              >
                <span style={{ fontSize: '12px' }}>
                  bereits <b className='black'>einen</b> Account
                </span>
              </Radio>
              <Radio
                checked={formTypeValue === 'REGISTER'}
                onChange={() => setFormTypeValue('REGISTER')}
              >
                <span style={{ fontSize: '12px' }}>
                  noch <b className='black'>keinen</b> Account
                </span>
              </Radio>
            </RadioContainer>
          </div>

          {formTypeValue === 'LOGIN' ? <LoginForm /> : <RegisterForm />}
        </div>
        <div className='auth-image-container login'>
          <div className='container-background' />
        </div>
      </div>
    </>
  );
};

export default Register;
