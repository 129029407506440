import axios from 'axios';
import ls from 'localstorage-slim';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../../config';
import isAuthenticated from '../../../redux/auth/authUtils';
import { setTextKeyVisibility } from '../../../redux/cache-slice';
import { setContracts } from '../../../redux/leaseApp/lease-app-slice';
import { RootState } from '../../../redux/types';
import { Contract } from '../../../types/instant-lease-api';
import { getAppStatus } from '../../../utils/getter';
import { errorLogging } from '../../../utils/logging';
import Navigation from '../../../utils/navigation';
import Button from '../../b2b/components/button/button';
import { ReactComponent as GlobeIcon } from '../../b2b/components/icons/globe.svg';
import { ReactComponent as TextIcon } from '../../b2b/components/icons/type.svg';
import PartnerLogo from '../../b2b/components/partner-logo/partner-logo';
import { ReactComponent as CarIcon } from '../icons/car-button.svg';
import { ReactComponent as ChevronDown } from '../icons/chevron-down-icon.svg';
import { ReactComponent as ChevronUp } from '../icons/chevron-up-icon.svg';
import { ReactComponent as ContactIcon } from '../icons/contact-icon.svg';
import { ReactComponent as ExternalLink } from '../icons/external-link-icon.svg';
import { ReactComponent as HelpIcon } from '../icons/help-icon.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout-button.svg';
import { ReactComponent as LogoutIconWhite } from '../icons/logout-white.svg';
import { ReactComponent as UserFilledIcon } from '../icons/user-icon-filled.svg';
import { ReactComponent as UserIcon } from '../icons/user-icon.svg';
import SubHeader from '../sub-header/sub-header';
import Tooltip from '../tooltip/tooltip';
import './header.css';

interface ContractAPIResponse {
  contracts: Contract[];
  message: string;
  status: number;
  timestamp: string;
}

const Header: React.FC = () => {
  // Cache Store
  const { showTextKey, isSubheaderVisible } = useSelector(
    (state: RootState) => state.cache,
  );

  // Local env
  const env = config.ENV;

  // Sequence Store
  const { device } = useSelector((state: RootState) => state.sequence);

  // Auth State
  const { emailVerified, accessToken, expiresAt, email } = useSelector(
    (state: RootState) => state.auth,
  );

  // Lease Application Storage
  const { activeApplication, leaseApplications, contracts } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Application status
  const status = getAppStatus(activeApplication);

  // dispatch hook
  const dispatch = useDispatch();

  // Header visibility
  const [visible, setVisible] = useState<boolean>(true);

  // Logout Tooltip visibility
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  // Sub-menu visibility
  const [subMenuVisibility, setSubMenuVisibility] = useState<boolean>(false);

  // Navigation
  const { nextRoute } = new Navigation();

  // Navigate Hook
  const navigate = useNavigate();

  // Current URL
  const url = useLocation().pathname;

  // Get previous contracts
  useEffect(() => {
    if (
      isAuthenticated({
        email: '',
        emailVerified: undefined,
        accessToken,
        authId: '',
        expiresAt,
        firstName: '',
        lastName: '',
        error: null,
      }) &&
      contracts &&
      contracts?.length <= 0
    ) {
      axios
        .get('/api/lease-application/documents/signed/contracts', {
          headers: {
            'Accept-Language': 'de-DE',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }: { data: ContractAPIResponse }) => {
          const { contracts } = data;
          if (contracts?.length > 0) {
            dispatch(setContracts(contracts));
          }
        })
        .catch((error) => errorLogging(new Error(`${error}`)));
    }
  }, [accessToken, contracts, dispatch, expiresAt]);

  // Control Header visibility
  useEffect(() => {
    const notAccessibleHeaderLocations = [
      'start',
      'login',
      'logout',
      'register',
      'reset-password',
      'no-offer',
      'contracts-history',
      'change-password',
    ];
    setTimeout(() => {
      setVisible(!notAccessibleHeaderLocations.includes(url.split('/')[1]));
    }, 100);
  }, [emailVerified, url]);

  // Disable scroll in case of desktop
  useEffect(() => {
    if (
      device === 'desktop' &&
      document.body.classList.contains('scroll-disable')
    ) {
      document.body.classList.remove('scroll-disable');
    }
  }, [device]);

  // Get User Full Name
  const getUserFullName = (): string => {
    const person =
      leaseApplications.length > 0
        ? leaseApplications[0].created_by?.person
        : activeApplication?.created_by?.person;

    const first = ls.get('first_name') || person?.first_name;
    const last = ls.get('last_name') || person?.last_name;

    if (first && last) {
      return `${first} ${last}`;
    }
    return '';
  };

  // Subheader control
  const toggleSubHeader = (forceState?: boolean) => {
    const bodyHasClass = document.body.classList.contains('scroll-disable');
    const isMobile = device === 'mobile';

    if (bodyHasClass && isMobile) {
      document.body.classList.remove('scroll-disable');
    }

    if (!bodyHasClass && isMobile) {
      document.body.classList.add('scroll-disable');
    }

    setSubMenuVisibility(forceState || !subMenuVisibility);
  };

  if (visible) {
    return (
      <div
        className={`header-view-status-container ${
          status === 'Cancelled' ? 'x' : ''
        } ${isSubheaderVisible ? 'active' : ''}`}
      >
        <div className='header' data-testid='header'>
          <div role='presentation'>
            <PartnerLogo data-testid='header-logo' className='image' />
          </div>

          {accessToken && (
            <div className='header-right-menu'>
              {subMenuVisibility ? (
                <>
                  <UserFilledIcon
                    data-testid='profile-icon'
                    className='pointer'
                    width='24px'
                    height='24px'
                    onClick={() => toggleSubHeader()}
                  />
                  <span
                    className={`pointer ${
                      subMenuVisibility
                        ? 'content-bold active'
                        : 'content-normal'
                    }`}
                    onClick={() => toggleSubHeader()}
                  >
                    {getUserFullName()}
                  </span>
                  <ChevronUp
                    className='arrow green'
                    onClick={() => toggleSubHeader()}
                  />
                </>
              ) : (
                <>
                  <UserIcon
                    data-testid='profile-icon'
                    className='pointer'
                    width='24px'
                    height='24px'
                    onClick={() => toggleSubHeader()}
                  />
                  <span
                    className='pointer content-normal'
                    onClick={() => toggleSubHeader()}
                  >
                    {getUserFullName()}{' '}
                  </span>
                  <ChevronDown
                    className='arrow'
                    onClick={() => toggleSubHeader()}
                  />
                </>
              )}

              <span className='mobile-hidden'>|</span>
              <div className='relative' style={{ display: 'flex' }}>
                <LogoutIcon
                  className={`button-text mobile-hidden logout ${
                    tooltipVisible ? 'active' : ''
                  }`}
                  data-testid='logout-Icon'
                  title='Abmeldung'
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                  onClick={() => {
                    setVisible(false);
                    navigate('/logout');
                  }}
                />
                <Tooltip text='Abmelden' visible={tooltipVisible} />
              </div>
            </div>
          )}

          {subMenuVisibility && (
            <div className='menu-header'>
              <div className='menu-header-body'>
                <h3 className='content-bold'>Hallo</h3>
                <p className='content-normal'>{getUserFullName()}</p>
                <p className='content-normal dark-grey'>
                  {email}
                  {!emailVerified && (
                    <>
                      {' '}
                      | <span className='light-grey'>Nicht verifiziert</span>
                    </>
                  )}
                </p>
                {contracts && contracts?.length > 0 && (
                  <div
                    className='header-section'
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open('/contracts-history', '_blank')}
                  >
                    <CarIcon />
                    <span className='content-normal dark-grey'>
                      Meine abgeschlossenen Verträge
                    </span>
                    <ExternalLink
                      width='20px'
                      height='20px'
                      className='link-icon'
                    />
                  </div>
                )}
                <div
                  className='header-section'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://www.ald-online.de/faq', '_blank')
                  }
                >
                  <HelpIcon />
                  <span className='content-normal dark-grey'>Hilfe | FAQ</span>
                  <ExternalLink
                    width='20px'
                    height='20px'
                    className='link-icon'
                  />
                </div>
                <div
                  className='header-section'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      'https://www.ald-online.de/pages/kontaktiere-uns',
                      '_blank',
                    )
                  }
                >
                  <ContactIcon />
                  <span className='content-normal dark-grey'>Kontakt</span>
                  <ExternalLink
                    width='20px'
                    height='20px'
                    className='link-icon'
                  />
                </div>

                <div
                  className='header-section logout-section mobile-only'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSubMenuVisibility(false);
                    navigate('/logout');
                  }}
                >
                  <LogoutIconWhite />
                  <span className='content-bold'>Jetzt abmelden</span>
                </div>
              </div>
              <Button
                type='button'
                className='submenu-close mobile-only bold'
                onClick={() => toggleSubHeader(false)}
              >
                Menü schließen
              </Button>
            </div>
          )}
        </div>

        <SubHeader />
        {(env === 'local' || env === 'dev') && (
          <>
            <div className='float-button'>
              {showTextKey ? (
                <TextIcon
                  width='25px'
                  height='25px'
                  onClick={() => dispatch(setTextKeyVisibility(!showTextKey))}
                />
              ) : (
                <GlobeIcon
                  width='25px'
                  height='25px'
                  onClick={() => dispatch(setTextKeyVisibility(!showTextKey))}
                />
              )}
            </div>
            {env !== 'dev' && (
              <Button
                type='button'
                className='pass-button'
                onClick={() => navigate(nextRoute())}
              >
                Pass
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return <></>;
};

export default Header;
