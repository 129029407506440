/* eslint-disable import/no-cycle */
import ls from 'localstorage-slim';
import { updateCustomerData } from '../redux/leaseApp/lease-app-slice';
import { Step } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import {
  APIPaths,
  ContactDetails,
  LeaseApplication,
  LegalForm,
  Partner,
  Person,
  Signer,
  Status,
  Type,
} from '../types/instant-lease-api';
import { getCoApplicant, getCountryByCode } from './getter';
import { validateCompanyData, validateDocuments } from './validatetor';

export const isIndividual = (legalForm: LegalForm): boolean => {
  const individualCompanies = [
    LegalForm.EINGETRAGENER_KAUFMANN,
    LegalForm.FREIE_BERUFE,
    LegalForm.GEWERBEBETRIEB,
  ];

  return !!individualCompanies.includes(legalForm);
};

export const isAnAdditionalSignerPossible = (
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  const legalForm =
    activeLeaseApp?.customer?.company?.legal_form ||
    activeLeaseApp?.customer?.individual?.legal_form;

  const signers =
    activeLeaseApp?.customer?.company?.signers ||
    activeLeaseApp?.customer?.individual?.signers ||
    [];

  const partner = activeLeaseApp?.partner;

  // All those three legal forms are individual companies and have only one signer though not allowing multisign
  if (
    legalForm === LegalForm.EINGETRAGENER_KAUFMANN ||
    legalForm === LegalForm.FREIE_BERUFE ||
    legalForm === LegalForm.GEWERBEBETRIEB
  ) {
    return !(signers.length > 0);
  }
  if (partner === Partner.ALD_FLEX) {
    return true;
  }
  // currently multisign is disabled for local flow. To enable it, return true;
  return true;
};

export const hasFilled = (
  route: string,
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  if (activeLeaseApp) {
    if (activeLeaseApp.customer?.individual) {
      const individual = activeLeaseApp.customer?.individual;
      switch (route) {
        case 'legal-form':
          return !!individual?.legal_form;
        case 'company-search':
          return !!individual?.name;
        case 'company-search-result':
          return !!individual?.name;
        case 'company-data':
          return validateCompanyData(activeLeaseApp);
        case 'identity-choice':
          return ls.get('ps') !== null;
        case 'owner-data':
          return !!individual?.owner;
        case 'documents':
          return validateDocuments(activeLeaseApp).exist;
        case 'start':
          return (
            activeLeaseApp.state === Status.CREDIT_INITIATED ||
            activeLeaseApp.state === Status.CREDIT_PENDING
          );
        default:
          return false;
      }
    } else if (activeLeaseApp.customer?.private) {
      switch (route) {
        case 'start':
          return (
            activeLeaseApp.state === Status.CREDIT_INITIATED ||
            activeLeaseApp.state === Status.CREDIT_PENDING
          );
        case 'review-contract':
          return (
            activeLeaseApp.state === Status.ESIGNATURE_PENDING ||
            activeLeaseApp.state === Status.ESIGNATURE_RETRY ||
            activeLeaseApp.state === Status.POSTIDENT_PENDING
          );
        case 'idnow':
          return (
            activeLeaseApp.state === Status.ESIGNATURE_PENDING ||
            activeLeaseApp.state === Status.ESIGNATURE_RETRY ||
            activeLeaseApp.state === Status.POSTIDENT_PENDING
          );
        default:
          return false;
      }
    } else {
      const company = activeLeaseApp.customer?.company;
      switch (route) {
        case 'legal-form':
          return !!company?.legal_form;
        case 'company-search':
          return !!company?.name;
        case 'company-search-result':
          return !!company?.name;
        case 'company-data':
          return validateCompanyData(activeLeaseApp);
        case 'commercial-register':
          return !!company?.trade_registry_number && !!company?.local_court;
        case 'transparency-register':
          return ls.get('tr') !== null;
        case 'letter-of-authority':
          return ls.get('loa') !== null;
        case 'identity-choice':
          return ls.get('ps') !== null;
        case 'owner-data':
          return company?.signers !== undefined && company?.signers.length > 0;
        case 'documents':
          return validateDocuments(activeLeaseApp).exist;
        case 'start':
          return (
            activeLeaseApp.state === Status.CREDIT_INITIATED ||
            activeLeaseApp.state === Status.CREDIT_PENDING
          );
        case 'review-contract':
          return (
            activeLeaseApp.state === Status.ESIGNATURE_PENDING ||
            activeLeaseApp.state === Status.ESIGNATURE_RETRY ||
            activeLeaseApp.state === Status.POSTIDENT_PENDING
          );
        case 'idnow':
          return (
            activeLeaseApp.state === Status.ESIGNATURE_PENDING ||
            activeLeaseApp.state === Status.ESIGNATURE_RETRY ||
            activeLeaseApp.state === Status.POSTIDENT_PENDING
          );
        default:
          return false;
      }
    }
  }
  return false;
};

/**
 * Returns true if all legal conditions are accepted.
 */
export const isAllLegalConditionsAccepted = (
  leaseApplication?: LeaseApplication | null,
): boolean => {
  if (!leaseApplication?.customer) {
    return false;
  }

  const {
    idnow_agreements: { terms_and_conditions: { answer: idNow } } = {
      terms_and_conditions: { answer: false },
    },
  } = leaseApplication.customer;

  const {
    ald_agreements: {
      data_storage: { answer: data_storage },
      third_party_processing: { answer: third_party_processing },
    } = {
      data_storage: { answer: false },
      third_party_processing: { answer: false },
      terms_and_conditions: { answer: false },
    },
  } = leaseApplication.customer;

  return Boolean(data_storage && third_party_processing && idNow);
};

export const hasTermsAndConditions = (
  leaseApplication?: LeaseApplication | null,
): boolean => {
  return (
    isAllLegalConditionsAccepted(leaseApplication) ||
    leaseApplication?.state === Status.CANCELLED
  );
};

// Clear timer if we got response
export const clearTimer = (timer: NodeJS.Timer): void => {
  clearInterval(timer);
};

export const isAdmin = (): boolean => {
  const accessToken = ls.get<string>('access_token', { decrypt: true });
  if (accessToken) {
    const parsedAccessToken = atob(accessToken?.split('.')[1]) as any;
    return JSON.parse(parsedAccessToken)?.permissions?.includes(
      'lease_application:admin',
    );
  }
  return false;
};

export const isCustomerService = (): boolean => {
  const accessToken = ls.get<string>('access_token');
  if (accessToken) {
    const parsedAccessToken = atob(accessToken?.split('.')[1]) as any;
    return JSON.parse(parsedAccessToken)?.permissions?.includes(
      'sandbox:lease_application',
    );
  }
  return false;
};

export const formatPhoneNumber = (
  phoneNumber: string | null,
  setValue: (...args: any[]) => void,
  trigger: (...args: any[]) => void,
): void => {
  if (phoneNumber && phoneNumber[0] === '0') {
    const newValue = phoneNumber.split('');
    newValue.shift();
    setValue('phone_number', newValue.toString().replaceAll(',', ''));
    trigger('phone_number');
  }
};

export const mapAddressField = (
  contact: ContactDetails | undefined,
  addressField: string,
): string | undefined => {
  if (contact?.addresses && contact?.addresses.length) {
    if (addressField === 'street_line') {
      const street_line = contact?.addresses[0].street_line;
      return street_line;
    }
    if (addressField === 'postal_code') {
      const postal_code = contact?.addresses[0].postal_code;
      return postal_code;
    }
    if (addressField === 'house_number') {
      const house_number = contact?.addresses[0].house_number;
      return house_number;
    }
    if (addressField === 'locality') {
      const locality = contact?.addresses[0].locality;
      return locality;
    }
    if (addressField === 'additional_lines') {
      const additional_lines = contact?.addresses[0].additional_lines
        ? contact?.addresses[0].additional_lines[0]
        : '';
      return additional_lines;
    }
    if (addressField === 'country') {
      const country = getCountryByCode(contact?.addresses[0].country_code)
        ?.label;
      return country;
    }
  }

  return undefined;
};

export const hasCommercialRegisterValidation = (
  activeApplication: LeaseApplication | null,
): boolean => {
  const LegalEntity =
    activeApplication?.customer?.company?.legal_form ||
    activeApplication?.customer?.individual?.legal_form;
  switch (LegalEntity) {
    case LegalForm.FREIE_BERUFE:
      return false;
    case LegalForm.GEWERBEBETRIEB:
      return false;
    case LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS:
      return false;
    case LegalForm.EINGETRAGENER_KAUFMANN:
      return false;
    case LegalForm.SONSTIGE_RECHTSFORM:
      return false;
    default:
      return true;
  }
};

export const hideCommercialRegisterSection = (
  activeApplication: LeaseApplication | null,
): boolean => {
  const LegalEntity =
    activeApplication?.customer?.company?.legal_form ||
    activeApplication?.customer?.individual?.legal_form;
  switch (LegalEntity) {
    case LegalForm.FREIE_BERUFE:
      return true;
    case LegalForm.GEWERBEBETRIEB:
      return true;
    case LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS:
      return true;
    case LegalForm.STIFTUNG:
      return true;
    default:
      return false;
  }
};

export const hideTransparenzregisterSection = (
  activeApplication: LeaseApplication | null,
): boolean => {
  const LegalEntity =
    activeApplication?.customer?.company?.legal_form ||
    activeApplication?.customer?.individual?.legal_form;
  switch (LegalEntity) {
    case LegalForm.EINGETRAGENER_KAUFMANN:
      return true;
    case LegalForm.PARTNERSCHAFTSGESELLSCHAFT:
      return true;
    case LegalForm.STIFTUNG:
      return true;
    case LegalForm.GMBH_CO_KG:
      return true;
    case LegalForm.GMBH_CO_KG_AA:
      return true;
    case LegalForm.AG_CO_KG:
      return true;
    default:
      return false;
  }
};

export const emailAlreadyUsed = (
  activeApplication: LeaseApplication | null,
  email: string,
  signer: Person | undefined,
): boolean => {
  if (signer) {
    const persons = activeApplication?.customer?.company?.signers?.filter(
      (alreadySigner) => alreadySigner?.person?.uuid !== signer?.uuid,
    );

    const alreadyUsed = persons?.some((x) =>
      x.person?.contact_details?.emails
        ? x.person?.contact_details?.emails[0]?.value === email
        : undefined,
    );

    return alreadyUsed ? true : false;
  }
  return false;
};

export const numberAlreadyUsed = (
  activeApplication: LeaseApplication | null,
  number: string,
): boolean => {
  const persons = activeApplication?.customer?.company?.signers;

  const alreadyUsed = persons?.some((x) =>
    x.person?.contact_details?.phone_numbers
      ? x.person?.contact_details?.phone_numbers[0]?.value === number
      : undefined,
  );
  if (alreadyUsed) return true;

  return false;
};

export const updateB2CCoSigner = async (
  activeApplication: LeaseApplication | null,
  accessToken: string,
  person: Signer,
) => {
  const previousSigners = activeApplication?.customer?.private?.signers;
  let signerCollection;
  const newSigners = previousSigners ? [...previousSigners] : [];
  const coSigner = getCoApplicant(newSigners);

  if (newSigners.length > 1) {
    const newArray = newSigners.map((item) =>
      item.uuid === coSigner?.uuid ? person : item,
    );
    signerCollection = newArray;
  } else {
    signerCollection = newSigners.length ? [...newSigners, person] : [person];
  }
  return store.dispatch(
    updateCustomerData({
      accessToken,
      leaseApplicationId: activeApplication?.uuid || '',
      inputValue: {
        signers: { data: signerCollection },
      },
      path: APIPaths.SIGNERS,
    }),
  );
};

export const updateB2CMainSigner = async (
  activeApplication: LeaseApplication | null,
  accessToken: string,
  person: Signer,
) => {
  const previousSigners = activeApplication?.customer?.private?.signers;
  let signerCollection;
  const newSigners = previousSigners ? [...previousSigners] : [];

  if (newSigners.length > 0) {
    const newArray = newSigners.map((item) =>
      item.uuid === newSigners[0].uuid ? person : item,
    );
    signerCollection = newArray;
  } else {
    signerCollection = newSigners.length ? [...newSigners, person] : [person];
  }

  return store.dispatch(
    updateCustomerData({
      accessToken,
      leaseApplicationId: activeApplication?.uuid || '',
      inputValue: {
        signers: { data: signerCollection },
      },
      path: APIPaths.SIGNERS,
    }),
  );
};

export const hasPostident = (
  activeApplication: LeaseApplication | null,
): Step[] => {
  if (activeApplication?.state === Status.POSTIDENT_PENDING) {
    return [
      {
        key: 'postIdenetIntro',
        name: 'postident starten',
        route: routes.leaseApplication.identityCheck.postidentStart,
      },
      {
        key: 'postIdenetCoupon',
        name: 'postident coupon',
        route: routes.leaseApplication.identityCheck.postidentCoupon,
      },
    ];
  }
  if (activeApplication?.type === Type.B2C) {
    return [
      {
        key: 'reviewContractB2C',
        name: 'Leasingvertrag',
        route: routes.leaseApplication.identityCheckB2C.reviewContractB2C,
      },
      {
        key: 'idNowB2C',
        name: 'Identifikation starten',
        route: routes.leaseApplication.identityCheckB2C.idNowB2C,
      },
      {
        key: 'idNowResponse',
        name: 'Identifikation status',
        route: routes.leaseApplication.identityCheckB2C.idNowResponse,
      },
    ];
  }

  return [
    {
      key: 'reviewContract',
      name: 'Leasingvertrag',
      route: routes.leaseApplication.identityCheck.reviewContract,
    },
    {
      key: 'idNow',
      name: 'Identifikation starten',
      route: routes.leaseApplication.identityCheck.idNow,
    },
  ];
};

export const hasSpecialOrderNumber = (
  activeApplication: LeaseApplication | null,
): { hasSpecialField: boolean; fieldName?: string; tooltip?: string } => {
  switch (activeApplication?.loyalty_card?.loyalty_card_type.toLowerCase()) {
    case 'fisker':
      return {
        hasSpecialField: true,
        fieldName: 'OC-Nummer',
        tooltip:
          'Bitte gebe Deine Bestellnummer vom Autohersteller ein. Die Bestellnummer kannst du deinem Bestellvertrag entnehmen, der dir auf der Seite des Herstellers zur Verfügung gestellt wurde.',
      };
    case 'smart_enpal':
      return {
        hasSpecialField: true,
        fieldName: 'Enpal-Order-ID',
        tooltip:
          'Bitte gebe hier Deine Enpal-Order-ID ein. Nur wenn ein Vertrag über den Bau einer Solaranlage mit der Firma Enpal erfolgreich zustande gekommen ist, kann der Leasingantrag zu den angebotenen Konditionen durch die Leasinggeberin angenommen werden.',
      };
    default:
      return { hasSpecialField: false };
  }
};

export const CapitalizeFirstLetter = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const includesAny = (arr: string[], values: string[]) =>
  values.some((v) => arr.includes(v));
