import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import {
  fetchLeaseApp,
  updateCustomerData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  LeaseApplication,
  LegalForm,
  Status,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { clearTimer } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import CreditCheckLoader from '../../../common/animation/credit-check-loader/credit-check-loader';
import Modal from '../../components/modal/modal';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CreditCheckProps {
  type: 'capital' | 'individual';
  extraInfo: string;
  getLegalform?: (
    leaseApp: LeaseApplication | null,
  ) => string | number | boolean | undefined;
}

const Creditcheck: React.FC<CreditCheckProps> = ({
  type,
  extraInfo,
  getLegalform,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Modal state
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  const [loaderText, setLoaderText] = useState<string>();

  // Get initial value
  const legalForm = getLegalform ? getLegalform(activeApplication) : '';

  // Start Credit Check Fn
  const startCreditCheck = () => {
    setModalVisibility(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          data_storage: true,
          data_usage: true,
        },
        path: APIPaths.SCHUFA_AGREEMENTS,
      }),
    );
    setLoaderText('Deine Bonitätsprüfung wurde gestartet');
    setTimeout(() => {
      setLoaderText('Wir bitten dich noch um etwas Geduld.');
    }, 5000);

    setTimeout(() => {
      setLoaderText('Deine Daten werden überprüft');
    }, 10000);
    setTimeout(() => {
      setLoaderText('Gleich geschafft');
    }, 15000);

    // Submit Credit Check Request
    submitTrigger(
      accessToken,
      activeApplication?.uuid || '',
      'start_credit_check',
    ).catch(() => {
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Das tut uns leid.',
          notificationBody: getText('error_unable_to_process_data'),
        }),
      );
      errorLogging(
        new Error(
          `unable to trigger credit check to user with application id ${activeApplication?.uuid}`,
        ),
      );
    });

    // Pull Lease App Every 1000ms
    const creditCheckTimer = setInterval(async () => {
      dispatch(
        fetchLeaseApp({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
        }),
      )
        .then((response) => {
          const leaseApp = response.payload as LeaseApplication;
          if (
            leaseApp.state === Status.CREDIT_SUCCESS ||
            leaseApp.state === Status.CREDIT_PENDING ||
            leaseApp.state === Status.CREDIT_FAILED
          ) {
            clearTimer(creditCheckTimer);
            navigate(nextRoute());
          }
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to get credit check response to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }, 1000);

    // Auto clear intervel and move to next page
    setTimeout(() => {
      clearTimer(creditCheckTimer);
      navigate(nextRoute());
    }, 30000);
  };

  return (
    <Layout heading={getText('credit_check_title')} subHeading={extraInfo}>
      {(type !== 'capital' ||
        legalForm === LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS) && (
        <>
          <h2 className='credit-check-title content-bold'>
            Wichtiger Hinweis für dich
          </h2>
          <div className='content-normal dark-grey'>
            Hinweis zur Datenübermittlung zwecks Bonitätsprüfung Sofern der
            Vertragsabschluss eine Bonitätsprüfung voraussetzt, werden Ihre
            Daten auf Grundlage des Art. 6 Abs.1 lit. f DSGVO an den Verband der
            Vereine Creditreform e.V. (Crefo), bzw. die Schufa Holding AG
            übermittelt. Übermittlungen auf der Grundlage von Art. 6 Abs. 1 lit.
            f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter
            Interessen der ALD erforderlich ist und nicht die Interessen oder
            Grundrechte und Grundfreiheiten der betroffenen Personen überwiegen.
            Informationen zur Datenverarbeitung durch die Schufa erhalten Sie{' '}
            <a
              href='mailto:www.meineschufa.de'
              target='_blank'
              rel='noopener noreferrer'
            >
              hier
            </a>{' '}
            .
          </div>
        </>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        buttonOneProps={{
          type: 'submit',
          onClick: startCreditCheck,
          dataTestId: 'next',
          children: 'Bonitätsprüfung starten',
        }}
      />

      <Modal
        isOpen={modalVisibility}
        onClose={() => modalVisibility}
        type='middle'
        withExitBtn={false}
        direction='down'
        background='default'
        style={{ top: '10%' }}
      >
        <h1 className='title center'>Einen Moment bitte...</h1>
        <p className='content-normal dark-grey center'>{loaderText}</p>
        <CreditCheckLoader />
      </Modal>
    </Layout>
  );
};

export default Creditcheck;
