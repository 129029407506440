import { yupResolver } from '@hookform/resolvers/yup';
import CountryList from 'country-list-with-dial-code-and-flag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { RootState } from '../../../../redux/types';
import { Person } from '../../../../types/instant-lease-api';
import { emailAlreadyUsed, formatPhoneNumber } from '../../../../utils/helper';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import TwoInputFields from '../../../common/two-input-fields/two-input-fields';
import Button from '../../components/button/button';
import InternationalPhoneInput from '../../components/international-phone-input/international-phone-input';

export interface ContactDataSubmittedValues {
  email: string;
  city: string;
  street: string;
  house_number: string;
  zip: string;
  phone_number: string;
}

interface ContactDataProps {
  onSubmit: (signer: ContactDataSubmittedValues) => void;
  signer?: Person;
  setError?: (t: boolean) => void;
  showSummary?: boolean;
  loading?: boolean;
}

const ContactData: React.FC<ContactDataProps> = ({
  onSubmit,
  signer,
  setError,
  loading,
}) => {
  // Will be used later
  const {
    leaseApp: { activeApplication },
  } = useSelector((state: RootState) => state);

  // Set country code when user pick flag
  const [countryCode, setCountry] = useState<string>('+49');

  // Form validation
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Bitte vollständige Email Adresse eintragen')
      .test('email', 'E-Mail bereits genutzt', (value) => {
        if (value) {
          if (emailAlreadyUsed(activeApplication, value, signer)) {
            return false;
          }
        }
        return true;
      })
      .required('Pflichtfeld'),
    city: yup
      .string()
      .matches(/^(|.{2,})$/)
      .required('Pflichtfeld'),
    street: yup.string().required('Pflichtfeld'),
    house_number: yup.string().required('Pflichtfeld'),
    zip: yup.string().required('Pflichtfeld'),
    phone_number: yup
      .string()
      .matches(
        /^[0-9][0-9]+$/gm,
        'Bitte geben Sie eine gültige Telefonnummer an (z.B.) 123456789',
      )
      .test('email', 'Mobiltelefonnummer bereits genutzt', (value) => {
        // if (value && !showSummary) {
        //   if (numberAlreadyUsed(activeApplication, `${countryCode}${value}`))
        //     return false;
        // }
        return true;
      })
      .required('Pflichtfeld'),
  });

  // Form Hook
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    formState: { isDirty },
  } = useForm<ContactDataSubmittedValues>({
    mode: 'onTouched',
    defaultValues: {},
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (
      !_.isEmpty(signer?.contact_details) &&
      signer?.contact_details?.emails?.length &&
      signer?.contact_details?.phone_numbers?.length &&
      signer?.contact_details?.addresses?.length
    ) {
      let count = 0;
      if (signer?.contact_details?.phone_numbers[0]?.value) {
        let matchedCountires = [];
        for (let i = 0; i <= 5; i++) {
          matchedCountires = CountryList.findByDialCode(
            signer?.contact_details?.phone_numbers[0]?.value?.slice(0, i),
          );

          if (matchedCountires.length === 1) {
            count = i;
          }
        }
      }

      // Remove intl code from number
      const phoneNumberWithoutIntlCode =
        signer?.contact_details?.phone_numbers[0]?.value
          ?.substring(count)
          .replace(/\s+/g, '');

      setCountry(
        signer?.contact_details?.phone_numbers[0]?.value?.slice(0, count) ||
          '+49',
      );

      setValue('email', signer?.contact_details?.emails[0]?.value || '', {
        shouldValidate: signer?.contact_details?.emails[0]?.value === '',
      });
      setValue('phone_number', phoneNumberWithoutIntlCode || '', {
        shouldValidate: phoneNumberWithoutIntlCode === '',
      });
      setValue(
        'street',
        signer?.contact_details?.addresses[0].street_line?.split(' ')[0] || '',
        {
          shouldValidate:
            signer?.contact_details?.addresses[0].street_line === '',
        },
      );
      setValue(
        'house_number',
        signer?.contact_details?.addresses[0].street_line?.split(' ')[1] || '',
        {
          shouldValidate:
            signer?.contact_details?.addresses[0].street_line === '',
        },
      );
      setValue('city', signer?.contact_details?.addresses[0].locality || '', {
        shouldValidate: signer?.contact_details?.addresses[0].locality === '',
      });
      setValue('zip', signer?.contact_details?.addresses[0].postal_code || '', {
        shouldValidate:
          signer?.contact_details?.addresses[0].postal_code === '',
      });
      trigger();
    }
  }, [signer, setValue, trigger]);

  // hook controlling error
  useEffect(() => {
    if (setError && isDirty) {
      setError(_.isEmpty(errors));
    }
  });

  // Phone number value
  formatPhoneNumber(getValues('phone_number'), setValue, trigger);

  // Form submit handler
  const formSubmitHandler = (values: ContactDataSubmittedValues) => {
    onSubmit({
      email: values.email,
      phone_number: `${countryCode}${values.phone_number}`,
      zip: values.zip,
      house_number: values.house_number,
      street: values.street,
      city: values.city,
    });
  };

  return (
    <FormLayout sectionTitle='Kontaktdaten'>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <TwoInputFields
          tooltip='Wie lautet Deine private Meldeadresse?'
          label='Straße | Hausnummer'
          gridTemplateColumns='70% 25%'
        >
          <InputField {...register('street')} error={errors?.street?.message} />
          <InputField
            {...register('house_number')}
            error={errors?.house_number?.message}
          />
        </TwoInputFields>
        <TwoInputFields
          label='Postleitzahl | Wohnort'
          gridTemplateColumns='25% 70%'
        >
          <InputField
            {...register('zip')}
            withErrorMessage={false}
            error={errors.zip?.message}
          />
          <InputField
            {...register('city')}
            withErrorMessage={false}
            error={errors.city?.message}
          />
        </TwoInputFields>
        <InputField
          label='E-Mail-Adresse'
          {...register('email')}
          error={errors?.email?.message}
        />
        <InternationalPhoneInput
          label='Mobiltelefonnummer'
          getCode={(value) => setCountry(value)}
          code={countryCode}
          placeholder='123456789'
          {...register('phone_number')}
          error={errors?.phone_number?.message}
        />
        <div className='add-additional-person'>
          <Button
            theme='secondary'
            type='button'
            className='save-button next button-text save-and-next-tab'
            role='presentation'
            loading={loading}
            onClick={handleSubmit(formSubmitHandler)}
          >
            nächster Schritt
          </Button>
        </div>
      </form>
    </FormLayout>
  );
};

export default ContactData;
