import { useEffect } from 'react';
import config from '../config';

const useMatomo = () => {
  // Matomo script
  const MatomoScript = () => {
    const scriptTag = document.querySelector(
      'script[src="https://t-log.sgmarkets.com/piwik.js"]',
    );

    if (
      localStorage.getItem('uc_user_interaction') === 'true' &&
      config.ALD_ENV === 'prd'
    ) {
      const UChistory = localStorage.getItem('uc_settings');
      if (UChistory) {
        const settings = JSON.parse(UChistory).services[0].history;
        const lastAction = settings[settings.length - 1].action;
        if (
          !scriptTag &&
          (lastAction === 'onAcceptAllServices' ||
            lastAction === 'onUpdateServices')
        ) {
          const browserWindow: any = window;
          var _paq = (browserWindow._paq = browserWindow._paq || []);
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function () {
            var u = 'https://t-log.sgmarkets.com/';
            _paq.push(['setTrackerUrl', u + 'piwik.php']);
            _paq.push(['setSiteId', '2000']);
            var d = document,
              g = d.createElement('script'),
              s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.async = true;
            g.src = u + 'piwik.js';
            s?.parentNode?.insertBefore(g, s);
          })();
        } else if (lastAction === 'onDenyAllServices' && scriptTag) {
          scriptTag.remove();
        }
      }
    }
  };

  // Hook
  useEffect(() => {
    const interval = setInterval(() => {
      MatomoScript();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useMatomo;
