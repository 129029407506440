import { ActionCreatorWithPayload, unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Authorities,
  BeneficialOwner,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import Collapsible from '../../components/collapsible/collapsible';
import { ReactComponent as AddPersonIcon } from '../../components/icons/add-person.svg';
import BOModal, { BoSubmittedValues } from '../../molecules/bo-modal/bo-modal';
import ButtonGroup from '../../molecules/button-group/button-group';
import Person from '../../molecules/person/person';
import './beneficial-owners.css';

interface BeneficialOwnersProps {
  title: string;
  subheadline?: string;
  emptyListTitle: string;
  BOModalTitle: string;
  createAction: ActionCreatorWithPayload<BeneficialOwner[] | undefined, string>;
  getInitialValue: (
    leaseApp: LeaseApplication | null,
  ) => BeneficialOwner[] | undefined;
  getLegalform: (
    leaseApp: LeaseApplication | null,
  ) => string | number | boolean | undefined;
}

const BeneficialOwners: React.FC<BeneficialOwnersProps> = ({
  title,
  subheadline,
  emptyListTitle,
  BOModalTitle,
  createAction,
  getInitialValue,
  getLegalform,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Navigation class
  const { nextRoute } = new Navigation();

  // owners state
  const [owners, setOwners] = useState<BeneficialOwner[]>([]);

  // Selected BeneficialOwner
  const [updateOwner, setUpdateOwner] = useState<BeneficialOwner>();

  // BO modal visibility state
  const [expanded, setExpanded] = useState<boolean>(false);

  // BO modal visibility state
  const [editOwner, setEditOwner] = useState<boolean>(false);

  // BO modal visibility state
  const [addNewPerson, setAddNewPerson] = useState<boolean>(false);

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // History hook
  const navigate = useNavigate();

  // Get initial value
  const legalform = getLegalform(activeApplication);

  // Dispatch Hook
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initialValues = getInitialValue(activeApplication)?.filter(
      (owner) => owner.person,
    );
    if (initialValues) setOwners(initialValues);
  }, [getInitialValue, activeApplication]);

  // Remove owner from the list
  const removeOwner = (bo: BeneficialOwner) => {
    setLoading(true);
    const updatedOwners = owners?.filter((owner) => owner?.uuid !== bo?.uuid);
    setOwners(updatedOwners);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          beneficial_owners: {
            data: updatedOwners,
          },
        },
        path: APIPaths.BENEFICIAL_OWNERS,
      }),
    ).then(() => setLoading(false));
  };

  const disableButton = (): boolean => {
    if (addNewPerson) return true;
    if (owners) return owners.length < 1;
    return false;
  };

  // Handling BO modal submit
  const onBOModalSubmit = (modifiedOwner: BoSubmittedValues) => {
    setLoading(true);
    // If owner selected so it is update
    if (editOwner) {
      const updatedOwners = owners?.map((owner: BeneficialOwner) => {
        const address =
          owner.person?.contact_details?.addresses &&
          owner.person?.contact_details?.addresses[0];
        return owner?.uuid === modifiedOwner?.uuid
          ? {
              ...owner,
              person: {
                ...owner.person,
                first_name: modifiedOwner?.first_name,
                last_name: modifiedOwner?.last_name,
                contact_details: {
                  ...owner.person?.contact_details,
                  addresses: [
                    {
                      postal_code: modifiedOwner?.zip,
                      street_line: address?.street_line,
                      locality: modifiedOwner.city,
                      additional_lines: address?.additional_lines,
                      country_code: address?.country_code,
                    },
                  ],
                },
              },
            }
          : owner;
      });

      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            beneficial_owners: {
              data: updatedOwners,
            },
          },
          path: APIPaths.BENEFICIAL_OWNERS,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setOwners(updatedOwners);
          setAddNewPerson(false);
          setUpdateOwner(undefined);
          setEditOwner(false);
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit beneficial owner to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    } // no owner selected so it is a new person
    else if (owners) {
      setOwners([
        ...owners,
        {
          uuid: modifiedOwner.uuid,
          person: {
            first_name: modifiedOwner.first_name,
            last_name: modifiedOwner.last_name,
            contact_details: {
              addresses: [
                {
                  postal_code: modifiedOwner?.zip,
                  street_line: '',
                  locality: modifiedOwner.city,
                  country_code: 'DEU',
                },
              ],
              phone_numbers: [],
              emails: [],
            },
          },
          role: Authorities.PROFESSIONAL_AUTHORITY_EXECUTIVE,
        },
      ]);
    }
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          beneficial_owners: {
            data: [
              ...owners,
              {
                uuid: modifiedOwner.uuid,
                person: {
                  first_name: modifiedOwner.first_name,
                  last_name: modifiedOwner.last_name,
                  contact_details: {
                    addresses: [
                      {
                        postal_code: modifiedOwner?.zip,
                        street_line: '',
                        locality: modifiedOwner.city,
                        country_code: 'DEU',
                      },
                    ],
                    phone_numbers: [],
                    emails: [],
                  },
                },
                role: Authorities.PROFESSIONAL_AUTHORITY_EXECUTIVE,
              },
            ],
          },
        },
        path: APIPaths.BENEFICIAL_OWNERS,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setLoading(false);
        setAddNewPerson(false);
        setUpdateOwner(undefined);
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        errorLogging(
          new Error(
            `unable to submit beneficial owner to user with application id ${activeApplication?.uuid}`,
          ),
        );
      });
  };

  // Next page
  const next = () => {
    dispatch(createAction(owners));
    navigate(nextRoute());
  };

  return (
    <Layout
      stepStatus
      data-testid='layout'
      className={`cr-layout ${
        owners && owners?.length >= 3 ? 'cr-margin' : ''
      }`}
      heading={owners && owners?.length > 0 ? title : emptyListTitle}
      subHeading={
        legalform === 'LEGAL_FORM_GESELLSCHAFT_BUERGERLICHEN_RECHTS'
          ? 'Gesellschafter und ggf. weitere Berechtigte'
          : subheadline
      }
    >
      {owners && owners?.length > 0 && (
        <div className='persons-list'>
          {owners.map((owner) => (
            <div key={owner?.uuid} className='collapsible'>
              <Person
                person={owner}
                editPersonOutside={setEditOwner}
                removePerson={removeOwner}
                onPersonSubmit={onBOModalSubmit}
                title={BOModalTitle}
                type='BO'
              />
            </div>
          ))}
        </div>
      )}
      {addNewPerson && (
        <div className='collapsible'>
          <Collapsible
            isOpen
            title='Personendaten'
            removeItem={() => {
              setAddNewPerson(false);
              setUpdateOwner(undefined);
            }}
          >
            <BOModal
              title={BOModalTitle}
              data-testid='KYC-modal'
              visibility
              owner={updateOwner}
              onClose={() => {
                setAddNewPerson(false);
                setUpdateOwner(undefined);
              }}
              toggleVisibility={() => setExpanded(!expanded)}
              toggleAdditionalModal={() => {
                setExpanded(!expanded);
              }}
              onSubmit={onBOModalSubmit}
            />
          </Collapsible>
        </div>
      )}
      <span
        role='presentation'
        data-testid='add-person'
        className='person-list-plus link-standalone'
        onClick={() => {
          setAddNewPerson(true);
          setUpdateOwner(undefined);
        }}
      >
        <AddPersonIcon />
        <span className='button-text'>Person hinzufügen</span>
      </span>
      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'submit',
          onClick: next,
          loading,
          disabled: disableButton(),
          name: 'form-submit',
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default BeneficialOwners;
