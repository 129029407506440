import React from 'react';
import './form-layout.css';

interface FormLayoutCommonProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionTitle?: string;
  sectionSubtitle?: string | any;
}

const FormLayout: React.FC<FormLayoutCommonProps> = ({
  className = '',
  style,
  sectionTitle,
  sectionSubtitle,
  children,
  ...props
}) => {
  return (
    <div className={`form-layout ${className}`} {...props} style={style}>
      <div className='form-section-title'>
        <h1
          data-testid='form-section-title'
          className='content-bold'
          style={{ marginBottom: '16px' }}
        >
          {sectionTitle}
        </h1>
        <p className='content-normal dark-grey content-normal'>
          {sectionSubtitle}
        </p>
      </div>

      <div className='form-content'>{children}</div>
    </div>
  );
};

export default FormLayout;
