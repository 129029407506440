import ls from 'localstorage-slim';
import { MetaData } from '../types/instant-lease-api';
import { getPartner } from '../utils/getter';

const useThemeHook = (definedPartner?: string, prefix?: string): void => {
  // Get partner
  const partner = definedPartner
    ? definedPartner.toLocaleLowerCase()
    : getPartner().toLocaleLowerCase();

  // Get theme and meta links
  const themeStyleSheet = document.getElementById('theme');
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  const metaTitle = document.getElementById('meta_title') as HTMLMetaElement;
  const metaDesc = document.getElementById('meta_desc') as HTMLMetaElement;
  const metaImage = document.getElementById('meta_image') as HTMLMetaElement;

  // Root style prevent theme flashing
  if (partner === 'lynkco') {
    document.documentElement.style.setProperty('--light-theme-bg', '#1B2021');
    document.documentElement.style.setProperty('--text-color', '#ffffff');
  } else {
    document.documentElement.style.setProperty('--light-theme-bg', '#ffffff');
    document.documentElement.style.setProperty('--text-color', '#1b2021');
  }

  const themePartner = partner === 'smart' ? 'ald_marktplatz' : partner;

  // Hook
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.id = 'theme';
  link.href = prefix
    ? `${prefix}/theme/${themePartner}/theme.css`
    : `/theme/${themePartner}/theme.css`;
  ls.set('partner', themePartner.toLocaleLowerCase());

  if (!themeStyleSheet) {
    document.head.appendChild(link);
  }

  if (
    themeStyleSheet &&
    themeStyleSheet.getAttribute('href') !== `/theme/${themePartner}/theme.css`
  ) {
    themeStyleSheet.remove();
    document.head.appendChild(link);
  }

  if (
    favicon &&
    metaTitle &&
    metaDesc &&
    metaImage &&
    MetaData[`${themePartner}` as keyof typeof MetaData] !== undefined
  ) {
    const { icon, title, desc, thumbnail } =
      MetaData[`${themePartner}` as keyof typeof MetaData];
    favicon.href = icon;
    metaTitle.content = title;
    metaDesc.content = desc;
    metaImage.content = thumbnail;
  }
};

export default useThemeHook;
