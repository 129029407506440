import './dot-animation.css';

interface DotAnimationProps {
  color?: 'white' | 'blue' | 'red';
}

const DotAnimation: React.FC<DotAnimationProps> = ({ color = 'white' }) => {
  return <div className={`dot-typing ${color}`} />;
};

export default DotAnimation;
