import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import './modal.css';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  withExitBtn?: boolean;
  background?: 'transparent' | 'default';
  type?: 'top' | 'middle' | 'full';
  direction?: 'up' | 'down';
  onClose?: () => void;
  isOpen: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  className,
  background = 'transparent',
  withExitBtn = true,
  type = 'top',
  onClose,
  direction = 'up',
  style,
}) => {
  const modalAnimations = {
    initial: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2, delay: 0.3 },
    },
  };

  const modalBodyAnimations = {
    initial: {
      opacity: 0,
      y: direction === 'up' ? -100 : 100,
      transition: {
        ease: 'anticipate',
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'anticipate',
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      y: direction === 'up' ? -100 : 100,
      transition: {
        ease: 'anticipate',
        duration: 0.3,
      },
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          data-testid='modal'
          className={`modal ${background}`}
          key='modal'
          variants={modalAnimations}
          initial='initial'
          animate='visible'
          exit='exit'
          onClick={(e) => e.currentTarget === e.target && onClose && onClose()}
        >
          <motion.div
            className={`modal-container ${type} ${className || ''}`}
            variants={modalBodyAnimations}
            initial='initial'
            animate='visible'
            exit='exit'
            style={style}
          >
            {withExitBtn && (
              <div
                className='mobile-hidden'
                style={{
                  position: 'absolute',
                  top: '4%',
                  right: '2%',
                }}
              >
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
              </div>
            )}
            <div className='modal-body left'>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
