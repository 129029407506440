import axios, { AxiosProgressEvent } from 'axios';
import ls from 'localstorage-slim';
import config from '../config';
import type {
  APIVersions as APIVersionsType,
  CarData,
} from '../types/instant-lease-api';
import {
  APIPaths,
  APIVersions,
  CrefoCompany,
  LeaseApplication,
  MarketingData,
  Partner,
  Type,
} from '../types/instant-lease-api';
import SanitizeDataValue, {
  formatLeaseApplication,
} from '../utils/sanitize-data';
import sandboxFunction from './sandbox-api';

export interface BankDetails {
  valid: boolean;
  messages: string[];
  iban: string;
  bankData: {
    bankCode: string;
    name: string;
    zip: string;
    city: string;
    bic: string;
  };
  checkResults: {
    bankCode: boolean;
  };
}

const sandboxIsActive = ls.get('sandbox', { decrypt: true }) === 'true';

export const fetchLeaseApplications = (
  accessToken: string,
): Promise<LeaseApplication[]> => {
  return sandboxIsActive
    ? ([sandboxFunction({ type: 'get' })] as any)
    : new Promise<LeaseApplication[]>((resolve, reject) => {
        axios
          .get<{ lease_applications: LeaseApplication[] }>(
            '/api/lease-application',
            {
              params: {
                active_only: true,
              },
              timeout: config.REQUEST_TIMEOUT,
              method: 'get',
              headers: {
                'Accept-Language': 'de-DE',
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then((response) => resolve(response?.data?.lease_applications))
          .catch((error) =>
            reject({ error: error, message: error?.response?.data }),
          );
      });
};

export const fetchLeaseApplication = (
  accessToken: string,
  leaseAppId: string,
): Promise<LeaseApplication> => {
  return sandboxIsActive
    ? new Promise<LeaseApplication>((resolve) => {
        resolve(sandboxFunction({ type: 'get' }));
      })
    : new Promise<LeaseApplication>((resolve, reject) => {
        axios
          .get<LeaseApplication>(`/api/lease-application/${leaseAppId}`, {
            timeout: config.REQUEST_TIMEOUT,
            method: 'get',
            headers: {
              'Accept-Language': 'de-DE',
              Accept: 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => resolve(formatLeaseApplication(response?.data)))
          .catch((error) =>
            reject({ error: error, message: error?.response?.data }),
          );
      });
};

export const fetchCompanyDetails = (
  accessToken: string,
  companyName: string,
  zipCode: string,
): Promise<CrefoCompany[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/crefo/search/${companyName}%20${zipCode}`, {
        timeout: config.REQUEST_TIMEOUT,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => resolve(data?.companyNameSuggestions))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const fetchCarDetails = ({
  publicationId,
  pricingId,
  offerId,
}: {
  publicationId?: string | undefined | null;
  pricingId?: string | undefined | null;
  offerId?: string | undefined | null;
}): Promise<any> => {
  if (publicationId && pricingId) {
    return new Promise((resolve, reject) => {
      axios
        .get<CarData>(`/v1/Campaigns/CarData/${publicationId}/${pricingId}`, {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            Accept: 'application/json',
          },
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject({ error: error }));
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get<CarData>(`/v1/Campaigns/CarData/flex/${offerId}`, {
        timeout: config.REQUEST_TIMEOUT,
        headers: {
          Accept: 'application/json',
        },
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject({ error: error }));
  });
};

export const fetchBankDetails = (iban: string): Promise<BankDetails> => {
  return new Promise<BankDetails>((resolve, reject) => {
    axios
      .get<BankDetails>(
        `/api/iban/validate/${iban}?validateBankCode=true&getBIC=true`,
        {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            Accept: 'application/json',
          },
        },
      )
      .then((response) => resolve(response?.data))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const getSavedUserOffer = async ({
  accessToken,
  email,
  offerId,
}: {
  accessToken: string;
  email: string;
  offerId?: string | null;
}): Promise<any> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .get<LeaseApplication>(
        `/api/lease-application/userdetails/${email}${
          offerId ? `/${offerId}` : ''
        }`,
        {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response: any) => resolve(response))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const saveUserOffer = async ({
  accessToken,
  email,
  offerId,
  vin,
  pricingId,
  publicationId,
  partner,
}: {
  accessToken: string;
  email: string;
  offerId: string;
  vin?: string;
  pricingId?: string;
  publicationId?: string;
  partner: string;
}): Promise<any> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .post<LeaseApplication>(
        `/api/lease-application/userdetails`,
        {
          email,
          offerId,
          vin,
          pricingId,
          publicationId,
          partner: partner.toUpperCase(),
        },
        {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response: any) => resolve(response))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const deleteSavedUserOffer = async ({
  accessToken,
  email,
  offerId,
}: {
  accessToken: string;
  email: string;
  offerId: string | null;
}): Promise<any> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .delete<LeaseApplication>(
        `/api/lease-application/userdetails/${offerId}-${email}`,
        {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response: any) => resolve(response))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const createLeaseApplication = ({
  offerId,
  partner = Partner.ALD,
  type = Type.B2B,
  pricingId,
  publicationId,
  vin,
  accessToken,
  loyaltyId,
  loyaltyType,
  marketingData,
}: {
  offerId: string;
  partner?: Partner;
  type?: Type;
  pricingId?: string;
  publicationId?: string;
  vin?: string;
  accessToken: string;
  loyaltyId?: string;
  loyaltyType?: string;
  marketingData?: MarketingData;
}): Promise<LeaseApplication> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .post<LeaseApplication>(
        '/api/lease-application/create',
        {
          type,
          partner,
          offer: {
            armada_id: offerId,
            publication: {
              pricing_id: pricingId,
              publication_id: publicationId,
            },
          },
          loyalty_card: {
            loyalty_card_id: loyaltyId,
            loyalty_card_type: loyaltyType,
          },
          vin,
          marketing_data: marketingData,
        },
        {
          timeout: config.REQUEST_TIMEOUT,
          method: 'post',
          headers: {
            'Accept-Language': 'de-DE',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response) => resolve(formatLeaseApplication(response?.data)))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const updateClient = async ({
  accessToken,
  leaseApplicationId,
  path,
  inputValue,
  version = APIVersions.BETA,
}: {
  accessToken: string;
  leaseApplicationId: string;
  path: APIPaths;
  inputValue: Record<string, unknown>;
  version?: APIVersionsType;
}): Promise<any> => {
  const SanitizedValue = await SanitizeDataValue(inputValue);
  return sandboxIsActive
    ? sandboxFunction({ path, inputValue: SanitizedValue, type: 'update' })
    : new Promise<LeaseApplication>((resolve, reject) => {
        axios
          .patch<LeaseApplication>(
            `/api/lease-application/${leaseApplicationId}/${path}`,
            { ...SanitizedValue, version },
            {
              timeout: config.REQUEST_TIMEOUT,
              method: 'patch',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then((response) => resolve(formatLeaseApplication(response?.data)))
          .catch((error) =>
            reject({ error: error, message: error?.response?.data }),
          );
      });
};

export const submitTrigger = (
  accessToken: string,
  leaseApplicationId: string,
  triggerCommand: string,
): Promise<LeaseApplication> => {
  return sandboxIsActive
    ? new Promise((resolve) =>
        resolve(sandboxFunction({ triggerCommand, type: 'trigger' })),
      )
    : new Promise<LeaseApplication>((resolve, reject) => {
        axios
          .post<LeaseApplication>(
            `/api/lease-application/${leaseApplicationId}`,
            { [triggerCommand]: {} },
            {
              timeout: config.REQUEST_TIMEOUT,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then((response) => resolve(formatLeaseApplication(response?.data)))
          .catch((error) =>
            reject({ error: error, message: error?.response?.data }),
          );
      });
};

export const flexAdditionalCheckoutData = async ({
  id,
}: {
  id: string;
}): Promise<any> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .get<LeaseApplication>(
        `/api/lease-application/flexAdditionalCheckoutData/${id}`,
        {
          timeout: config.REQUEST_TIMEOUT,
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => resolve(formatLeaseApplication(response?.data)))
      .catch((error) =>
        reject({ error: error, message: error?.response?.data }),
      );
  });
};

export const getDocument = async (
  accessToken: string,
  applicationId: string,
  token: string,
  isContract?: boolean,
): Promise<any> => {
  const url = isContract
    ? `/api/lease-application/${applicationId}/document/doxis/${token}`
    : `/api/lease-application/${applicationId}/document/${token}`;
  return sandboxIsActive
    ? sandboxFunction({})
    : new Promise((resolve, reject) =>
        axios({
          method: 'GET',
          url,
          headers: {
            'content-type': 'application/octet-stream',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        })
          .then((response) => resolve(response?.data))
          .catch((error) => reject(error)),
      );
};

export const uploadDocument = async (
  accessToken: string,
  applicationId: string,
  documentType: string,
  file: FormData,
  uploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined,
): Promise<LeaseApplication> =>
  sandboxIsActive
    ? sandboxFunction({})
    : new Promise((resolve, reject) =>
        axios({
          method: 'POST',
          url: `/api/lease-application/${applicationId}/documents`,
          data: file,
          params: {
            document_type: documentType,
          },
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
          onUploadProgress: uploadProgress,
        })
          .then((response) => resolve(formatLeaseApplication(response?.data)))
          .catch((error) => reject(error)),
      );

export const deleteDocument = async (
  accessToken: string,
  applicationId: string,
  documentToken: string,
  documentType: string,
): Promise<LeaseApplication> =>
  sandboxIsActive
    ? sandboxFunction({})
    : new Promise((resolve, reject) =>
        axios({
          method: 'DELETE',
          url: `/api/lease-application/${applicationId}/document/${documentToken}`,
          params: {
            document_type: documentType,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(({ data }: { data: LeaseApplication }) =>
            resolve(formatLeaseApplication(data)),
          )
          .catch((error) => reject(error)),
      );

export const cancelLeaseApplication = (
  accessToken: string,
  leaseApplicationId: string,
): Promise<LeaseApplication> => {
  return new Promise<LeaseApplication>((resolve, reject) => {
    axios
      .delete<LeaseApplication>(
        `/api/lease-application/${leaseApplicationId}`,
        {
          timeout: config.REQUEST_TIMEOUT,
          headers: {
            'Accept-Language': 'de-DE',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response) => resolve(response?.data))
      .catch((error) => reject(error));
  });
};
