import { WebAuth } from 'auth0-js';
import ls from 'localstorage-slim';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';
import { setUserInformation } from '../redux/auth/auth-slice';
import { isAuthenticated } from '../redux/auth/authUtils';
import { RootState } from '../redux/types';

const useUserInfo = (): void => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isAuthenticated(auth)) {
      const webAuth = new WebAuth({
        domain: config.AUTH0_DOMAIN,
        clientID: config.AUTH0_CLIENT_ID,
        redirectUri: `${window.location.origin}/callback`,
        audience: config.AUTH0_AUDIENCE,
        responseType: 'id_token token',
      });
      if (config.ENV === 'local') {
        dispatch(
          setUserInformation({
            firstName: ls.get('first_name') || '',
            lastName: ls.get('last_name') || '',
            emailVerified: true,
            email: ls.get('email') || '',
          }),
        );
      } else {
        webAuth.client.userInfo(auth.accessToken, (err, user) => {
          const nickname = user?.nickname.split('.');
          const firstName =
            user?.user_metadata?.given_name ||
            user?.given_name ||
            _.startCase(nickname[0]) ||
            ls.get('first_name') ||
            '';
          const lastName =
            user?.user_metadata?.family_name ||
            user?.family_name ||
            _.startCase(nickname[1]) ||
            ls.get('last_name') ||
            '';
          if (user) {
            dispatch(
              setUserInformation({
                firstName,
                lastName,
                emailVerified: user.email_verified || false,
                email: user.email || '',
              }),
            );
          }
        });
      }
    }
  }, [auth, dispatch]);
};

export default useUserInfo;
