import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import countries from 'i18n-iso-countries';
import React, { useEffect, useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import InputField from '../../../common/input-field/input-field';
import { ReactComponent as ChevronDown } from '../icons/chevron-down.svg';
import './international-phone-input.css';

interface InternationalPhoneInputProps extends React.HTMLAttributes<any> {
  label: string;
  name: string;
  getCode: (value: string) => void;
  code?: string;
  placeholder?: string;
  error: string | undefined;
}

const InternationalPhoneInput = React.forwardRef<
  any,
  InternationalPhoneInputProps
>(
  (
    {
      label,
      name,
      error,
      placeholder,
      getCode,
      code,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    // Select countries local
    countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

    // Code State
    const [countryCode, setCountryCode] = useState<string>(code || '+49');

    // Flag State
    const [flag, setFlag] = useState<string>('DE');

    // Active State
    const [active, setActive] = useState<boolean>(false);

    // On click in flag area
    const handleOnClick = () => {
      setActive(!active);
    };

    // If there is a selected country already
    useEffect(() => {
      if (code) {
        setCountryCode(code);
        const selectedCountry = CountryList.findByDialCode(code)[0];
        setFlag(selectedCountry.code);
      }
    }, [code]);

    // On change in value
    const handleOnChange = (value: string) => {
      setActive(false);
      getCode(value);
      if (value.length > 2 && value[0] === '+') {
        setCountryCode(value);
      }
    };

    // Get list of countries
    const getCountriesList = () => {
      const list = CountryList.getAll()
        .filter((country) => {
          // Filter missing flag countires
          if (country.name === 'Netherlands Antilles') {
            return false;
          }
          if (country.name === 'Antarctica') {
            return false;
          }
          if (country.name === 'Canary Islands') {
            return false;
          }
          if (country.name === 'Caribbean Netherlands') {
            return false;
          }
          if (country.name === 'Western Sarah') {
            return false;
          }

          return country;
        })
        .map((country): Country => {
          return {
            name: countries.getName(country.code, 'de'),
            code: country.code,
            flag: country.flag,
            dial_code: country.dial_code,
          } as Country;
        })
        .sort((countryA, countryB) => {
          // Sort all countries
          if (countryA.name < countryB.name) {
            return -1;
          }

          if (countryA.name > countryB.name) {
            return 1;
          }

          return 0;
        })
        .sort((countryA, countryB) => {
          // Add Germany to top
          if (countryA.name === 'Deutschland') {
            return -1;
          }
          if (countryB.name === 'Deutschland') {
            return 1;
          }

          // Add Austria to top
          if (countryA.name === 'Österreich') {
            return -1;
          }
          if (countryB.name === 'Österreich') {
            return 1;
          }

          // Add Switzerland to top
          if (countryA.name === 'Schweiz') {
            return -1;
          }

          if (countryB.name === 'Schweiz') {
            return 1;
          }

          // Add Ägypten to top
          if (countryA.name === 'Ägypten') {
            return -1;
          }

          if (countryB.name === 'Ägypten') {
            return 1;
          }

          // Add Äquatorialguinea to top
          if (countryA.name === 'Äquatorialguinea') {
            return -1;
          }

          if (countryB.name === 'Äquatorialguinea') {
            return 1;
          }

          // Add Äthiopien to top
          if (countryA.name === 'Äthiopien') {
            return -1;
          }

          if (countryB.name === 'Äthiopien') {
            return 1;
          }

          // Add Åland to top
          if (countryA.name === 'Åland') {
            return -1;
          }

          if (countryB.name === 'Åland') {
            return 1;
          }

          return 0;
        });
      return list;
    };

    return (
      <InputField
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        icon={
          <>
            <div
              className='icon-container'
              role='presentation'
              onClick={() => handleOnClick()}
            >
              <span className='icon'>
                {' '}
                <FlagIcon
                  code={flag as any}
                  size={13}
                  width='auto'
                  style={{ display: 'inline', width: 'auto' }}
                />
              </span>
              <span className='input-text'>{countryCode}</span>
              <ChevronDown className='input-intl-number-arrow' />
            </div>
            <ul
              className={`intl-countries-list ${active ? 'active' : ''}`}
              role='presentation'
            >
              {getCountriesList().map((country, index) => (
                <li
                  key={index}
                  className='input-text intl-country'
                  role='presentation'
                  onClick={() => {
                    handleOnChange(country.dial_code);
                    setFlag(country.code);
                  }}
                >
                  <span>
                    <FlagIcon
                      code={country.code as any}
                      size={13}
                      width='auto'
                      style={{ display: 'inline', width: 'auto' }}
                    />
                  </span>
                  <span>{country.name}</span>
                  <span>{country.dial_code}</span>
                </li>
              ))}
            </ul>
          </>
        }
        {...props}
        label={label}
        error={error}
        placeholder={placeholder}
      />
    );
  },
);

export default InternationalPhoneInput;
