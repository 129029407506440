import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import ls from 'localstorage-slim';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { setNotification } from '../../../../redux/cache-slice';
import { useAppDispatch } from '../../../../redux/store';
import { getText } from '../../../../utils/getter';
import Button from '../../../b2b/components/button/button';
import PartnerLogo from '../../../b2b/components/partner-logo/partner-logo';
import InputField from '../../input-field/input-field';
import PasswordForgetImage from '../images/password-forget-image.jpeg';

const ResetPassword: React.FC = () => {
  // Dispatch hook
  const dispatch = useAppDispatch();

  // Form submitted states
  const [submitted, setSubmitted] = useState<boolean>(false);

  // Form loading states
  const [loading, setLoading] = useState<boolean>(false);

  // Validation Schema
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(getText('validation_address'))
      .required('Pflichtfeld'),
  });

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // submit form
  const submit = ({ email }: { email: string }) => {
    setLoading(true);
    axios
      .post(`/api/notification-v2/reset-password`, {
        partner: ls.get('partner') || 'ald_marktplatz',
        email,
      })
      .then(() => {
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationTitle: 'E-Mail wurde gesendet',
            notificationBody: getText('reset_password_text_success'),
          }),
        );
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        setLoading(false);
        setSubmitted(true);
      });
  };

  return (
    <div className='auth-container'>
      <div className='auth-form-container login' style={{ minHeight: '950px' }}>
        <div className='auth-logo'>
          <PartnerLogo />
        </div>
        <form
          className='auth-form-content login'
          data-testid='form'
          onSubmit={handleSubmit(submit)}
        >
          <div className='auth-form'>
            <div>
              <h2 className='title bottom-24'>Zurücksetzen deines Passworts</h2>
            </div>
            <div className='input-container'>
              <InputField
                className='row-input-field'
                label='E-Mail-Adresse'
                {...register('email')}
                error={errors.email?.message}
              />
              <p className='content-normal left dark-grey'>
                {getText('password_text')}
              </p>
            </div>
          </div>

          <Button
            type='submit'
            className='content-bold top-40'
            loading={loading}
            disabled={submitted}
          >
            Passwort zurücksetzen
          </Button>

          <p className='content-normal dark-grey top-16'>
            Du hast bereits einen Account? Dann{' '}
            <Link to='/login' className='bold'>
              jetzt anmelden.
            </Link>
          </p>
        </form>
      </div>
      <div className='auth-image-container login'>
        <div
          className='container-background'
          style={{
            backgroundImage: `url(${PasswordForgetImage})`,
            backgroundPosition: 'center',
          }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
