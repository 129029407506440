import ls from 'localstorage-slim';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import isAuthenticated from '../redux/auth/authUtils';
import { fetchCarDetails } from '../redux/leaseApp/lease-app-slice';
import store, { useAppDispatch } from '../redux/store';
import { Offer } from '../types/instant-lease-api';

const useCarData = (): void => {
  // Offer data from localstorage if exist
  const [offer] = useState<Offer | null>(ls.get<Offer>('oid'));

  // Dispatch hook
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Get active lease application state from store
    const activeApplication = store.getState().leaseApp?.activeApplication;

    // Get carData state from store
    const carData = store.getState().leaseApp?.carData;

    // Get carData state from store
    const auth = store.getState().auth;

    // Current Window URL
    const url = window.location.pathname.split('/');

    // Car data from localstorage if exist
    const carDetails = ls.get<{
      publicationId: string;
      pricingId: string;
      offerId: string;
    }>('carData');

    const getCarDetails = () => {
      // Temp variables
      let publicationId: string | undefined;
      let pricingId: string | undefined;
      let offerId: string | undefined;

      // Get car details from localstorage in case of use not Authenticated
      if (carDetails && !isAuthenticated(auth)) {
        publicationId = carDetails?.publicationId;
        pricingId = carDetails?.pricingId;
        offerId = carDetails?.offerId;
      }

      if (carDetails && !activeApplication) {
        publicationId = carDetails?.publicationId;
        pricingId = carDetails?.pricingId;
        offerId = carDetails?.offerId;
      }

      // Get car details from offer
      if (offer) {
        publicationId = offer?.publicationId;
        pricingId = offer?.pricingId;
        offerId = offer?.offerId;

        // Set car data in localstorage to reterive it later
        ls.set('carData', { pricingId, publicationId, offerId });
      }

      // Get car details from activeApplication in case of user authenticated
      if (activeApplication) {
        publicationId = activeApplication?.offer?.publication?.publication_id;
        pricingId = activeApplication?.offer?.publication?.pricing_id;
        offerId = activeApplication?.offer?.armada_id;
      }

      // Get car data only if the carData cache isn't filled
      if (!_.isEmpty(publicationId) && !_.isEmpty(pricingId)) {
        dispatch(fetchCarDetails({ publicationId, pricingId }));
      } else if (!_.isEmpty(offerId)) {
        dispatch(fetchCarDetails({ offerId }));
      }
    };

    if (
      !carData &&
      url[1] !== 'start' &&
      url[1] !== 'b2cOffer' &&
      url[1] !== 'offer'
    ) {
      getCarDetails();
    }
  }, [dispatch, offer]);
};

export default useCarData;
