import React from 'react';
import { useSelector } from 'react-redux';
import { getDocument } from '../../../api/instant-lease-api';
import Layout from '../../../layout';
import { setNotification } from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { getText } from '../../../utils/getter';
import ArrowList from '../../b2b/components/arrow-list/arrow-list';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import { ReactComponent as PostidentLogo } from '../images/postident-logo.svg';
import { ReactComponent as PostidentStepOne } from '../images/postident-step-one.svg';
import { ReactComponent as PostidentStepThree } from '../images/postident-step-three.svg';
import { ReactComponent as PostidentStepTwo } from '../images/postident-step-two.svg';

const Postident: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Handle download coupon
  const handleDownloadCoupon = () => {
    const coupon = activeApplication?.registered_documents?.find(
      (doc) => doc.file_name === 'postident_coupon.pdf',
    );
    if (coupon) {
      const { token, file_name, mime_type } = coupon;
      getDocument(accessToken, activeApplication?.uuid || '', token)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          link.download = file_name;
          link.click();
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    } else {
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Das tut uns leid.',
          notificationBody:
            'Ihr Coupon ist leider noch nicht bereit, bitte wiederholen Sie den Vorgang später.',
        }),
      );
    }
  };

  return (
    <Layout
      heading='Sicher und einfach: Legitimation mit PostIdent'
      subHeading='Jetzt einfach deinen Antrag ausdrucken und in der nächsten Postfiliale deine Identität nachweisen.'
    >
      <div className='grid-template'>
        <div className='idnow-info'>
          <span className='title bottom-24'>
            So funktioniert die Legitimation mit <PostidentLogo />
          </span>
          <div className='idnow-info-details'>
            <div className='idnow-info-steps'>
              <span className='idnow-step'>1.</span>
              <PostidentStepOne />
              <span className='content-normal '>
                Lade die Unterlagen herunter und drucke das ausgefüllte Dokument
                aus.
              </span>
            </div>
            <div className='idnow-info-steps'>
              <span className='idnow-step'>2.</span>
              <PostidentStepTwo />
              <span className='content-normal'>
                Gehe mit deinen Unterlagen und deinem Ausweisdokument zu einer
                Postfiliale.
              </span>
            </div>
            <div className='idnow-info-steps'>
              <span className='idnow-step'>3.</span>
              <PostidentStepThree />
              <span className='content-normal'>
                Dein unterschriebenes Dokument wird von einem Post-Mitarbeiter
                an uns gesendet und wir melden uns nach Erhalt der Unterlagen
                bei dir.
              </span>
            </div>
          </div>
        </div>
        <div>
          <h2 className='content-bold'>Das benötigst du:</h2>
          <ArrowList
            className='content-normal'
            list={[
              'Die unterschriebenen Unterlagen',
              'Personalausweis oder Reisepass in Verbindung mit einer aktuellen Meldebescheinigung',
            ]}
          />
          <ButtonGroup
            type='back-next'
            className='back-next medium'
            buttonOneProps={{
              type: 'submit',
              onClick: () => handleDownloadCoupon(),
              dataTestId: 'next',
              children: 'Unterlagen herunterladen',
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Postident;
