import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { getAGBLink, getPartnerText } from '../../../utils/getter';
import './footer.css';

const Footer: React.FC = () => {
  // Redux Store
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  return (
    <div className='footer sub-normal' data-testid='footer'>
      <div className='container'>
        <div className='row'>
          <div className='left-column'>
            <ul>
              <li>
                <a
                  data-testid='datenschutz'
                  href='https://www.ald-online.de/pages/datenschutz'
                  target='_blank'
                  rel='noreferrer'
                >
                  Datenschutzhinweise Website
                </a>
              </li>
              <li>
                <a
                  data-testid='datenschutz'
                  href='https://www.ald-online.de/pages/datenschutzhinweise'
                  target='_blank'
                  rel='noreferrer'
                >
                  Datenschutzhinweise für Kunden
                </a>
              </li>
              <li>
                <a
                  href='#/'
                  onClick={() => {
                    const browserWindow: any = window;
                    if (browserWindow.UC_UI) {
                      browserWindow.UC_UI.showSecondLayer();
                    }
                  }}
                >
                  Privatsphäre-Einstellungen
                </a>
              </li>
              <li>
                <a
                  data-testid='impressum'
                  href='https://www.ald-online.de/pages/impressum'
                  target='_blank'
                  rel='noreferrer'
                >
                  Impressum
                </a>
              </li>
              <li>
                <a
                  data-testid='agb'
                  href={getAGBLink(activeApplication)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {getPartnerText('agb_text')}
                </a>
              </li>
            </ul>
          </div>
          <div className='right-column'>
            <ul>
              <li>Geschäftszeiten:</li>
              <li>{getPartnerText('opening_hours')}</li>
              <li>Tel: {getPartnerText('contact_us_number')}</li>
              <li>
                <a href={`mailto:${getPartnerText('contact_us_email')}`}>
                  {getPartnerText('contact_us_email')}
                </a>
              </li>
              <li>
                <a
                  href={`https://${getPartnerText('footer_url')}/`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {getPartnerText('footer_url')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='full-width-column'>
          {getPartnerText('bottom_description')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
