import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EDevice } from '../../../../redux/sequence/enums';
import { RootState } from '../../../../redux/types';
import Button from '../../../b2b/components/button/button';
import { ReactComponent as ContractCancel20 } from '../../../b2b/components/icons/contract_delete_20.svg';
import { ReactComponent as ContractCancel24 } from '../../../b2b/components/icons/contract_delete_24.svg';
import Modal from '../../../b2b/components/modal/modal';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';

interface ConfirmationProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  onClose: () => void;
  onLoading?: boolean;
  confirmationText: string;
  cancel: () => void;
  success: () => void;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  isOpen,
  onClose,
  confirmationText,
  cancel,
  onLoading,
  success,
  children,
}) => {
  // Lease Applications Store
  const { device } = useSelector((state: RootState) => state.sequence);

  // Hide the next button
  useEffect(() => {
    const nextButton = document.getElementsByClassName('back-next-container');
    if (isOpen && nextButton && nextButton.length > 0) {
      nextButton[0].classList.add('back-next-containers');
      nextButton[0].classList.remove('back-next-container');
    }

    if (!isOpen) {
      const nextButton = document.getElementsByClassName(
        'back-next-containers',
      );
      if (nextButton && nextButton.length > 0) {
        nextButton[0].classList.add('back-next-container');
        nextButton[0].classList.remove('back-next-containers');
      }
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        type='middle'
        withExitBtn={false}
        direction='up'
        background='transparent'
      >
        <h1 className='title left bottom-24'>{confirmationText}</h1>
        {children}

        <ButtonGroup
          type='default'
          className='top-48'
          buttonOneProps={{
            theme: 'secondary',
            type: 'button',
            disabled: onLoading,
            className: 'mobile-hidden',
            children: 'Fortsetzen',
            onClick: () => cancel(),
          }}
          buttonTwoProps={{
            theme: 'danger',
            type: 'button',
            loading: onLoading,
            onClick: () => success(),
            children: (
              <span className='cancel-button '>
                {device === EDevice.MOBILE ? (
                  <ContractCancel20 />
                ) : (
                  <ContractCancel24 />
                )}
                Leasingantrag abbrechen
              </span>
            ),
          }}
        />
      </Modal>
      {isOpen && (
        <Button
          type='button'
          theme='secondary'
          className='button-ontop mobile-only'
          disabled={onLoading}
          onClick={cancel}
        >
          Fortsetzen
        </Button>
      )}
    </>
  );
};

export default ConfirmationModal;
