import React from 'react';
import Button, { ButtonProps } from '../../components/button/button';
import { ReactComponent as ArrowBackwardIcon } from '../../components/icons/chevron-left.svg';

interface ButtonGroupCommonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  next?: () => void;
  scrollable?: boolean;
  buttonOneProps: ButtonProps & { dataTestId?: string };
  buttonTwoProps?: ButtonProps & { dataTestId?: string };
  appearOnFront?: boolean;
}

interface ButtonsLayout extends ButtonGroupCommonProps {
  type: 'default';
}

interface ArrowLayout extends ButtonGroupCommonProps {
  type: 'arrow';
}

interface BackNextButtonLayout extends ButtonGroupCommonProps {
  type: 'back-next';
}

type ButtonGroupProps = ButtonsLayout | ArrowLayout | BackNextButtonLayout;

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  type,
  scrollable = false,
  buttonOneProps,
  buttonTwoProps,
  appearOnFront,
  className = '',
  ...props
}: ButtonGroupProps) => {
  if (type === 'arrow') {
    return (
      <div
        {...props}
        className={`button-group arrow ${className} ${
          scrollable ? 'scrollable' : ''
        } ${
          buttonTwoProps && buttonTwoProps.theme !== 'secondary'
            ? 'central'
            : ''
        }`}
      >
        <Button
          ref={buttonOneProps.ref as React.RefObject<any>}
          name={buttonOneProps.name}
          type={buttonOneProps.type}
          loading={buttonOneProps.loading}
          onClick={buttonOneProps.onClick}
          theme={buttonOneProps.theme}
          style={{ ...buttonOneProps.style }}
          arrow={buttonOneProps.arrow}
          disabled={buttonOneProps.disabled}
          data-testid={buttonOneProps.dataTestId}
        />
        {buttonTwoProps && (
          <span
            className={`${
              buttonTwoProps.className ? buttonTwoProps.className : ''
            } button-text`}
            style={{ ...buttonTwoProps.style }}
            role='presentation'
            onClick={buttonTwoProps.onClick}
            data-testid={buttonTwoProps.dataTestId}
          >
            {buttonTwoProps.children}
          </span>
        )}
      </div>
    );
  }

  if (type === 'back-next') {
    return (
      <div
        className={`back-next-container ${
          appearOnFront ? 'appear-on-front' : ''
        }`}
      >
        {className !== 'back-next full' && <div className='empty-div' />}
        <div
          {...props}
          className={`button-group  ${className} ${
            scrollable ? 'scrollable' : ''
          } ${buttonTwoProps ? 'central' : ''}`}
        >
          <Button
            className={buttonOneProps.className}
            ref={buttonOneProps.ref as React.RefObject<any | null>}
            name={buttonOneProps.name}
            style={buttonOneProps.style}
            loading={buttonOneProps.loading}
            onClick={buttonOneProps.onClick}
            type={buttonOneProps.type}
            theme={buttonOneProps.theme}
            disabled={buttonOneProps.disabled}
            data-testid={buttonOneProps.dataTestId}
          >
            <div className='next-button'>
              <span className='button-text'>{buttonOneProps.children}</span>
            </div>
          </Button>
          {buttonTwoProps && (
            <Button
              className={buttonTwoProps.className}
              name={buttonTwoProps.name}
              loading={buttonTwoProps.loading}
              onClick={buttonTwoProps.onClick}
              type={buttonTwoProps.type}
              style={buttonTwoProps.style}
              disabled={buttonTwoProps.disabled}
              theme={buttonTwoProps.theme}
              data-testid={buttonTwoProps.dataTestId}
            >
              {' '}
              {buttonTwoProps.className === 'back-button' ? (
                <div className='back-button'>
                  <ArrowBackwardIcon />
                  <span className='button-text'>{buttonTwoProps.children}</span>
                </div>
              ) : (
                <div className='next-button'>
                  <span className='button-text'>{buttonTwoProps.children}</span>
                </div>
              )}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      {...props}
      className={`button-group default ${className} ${
        scrollable ? 'scrollable' : ''
      } ${buttonTwoProps ? 'central' : ''}`}
    >
      {buttonTwoProps && (
        <Button
          className={buttonTwoProps.className}
          ref={buttonTwoProps.ref as React.RefObject<any | null>}
          name={buttonTwoProps.name}
          loading={buttonTwoProps.loading}
          onClick={buttonTwoProps.onClick}
          type={buttonTwoProps.type}
          theme={buttonTwoProps.theme}
          style={buttonTwoProps.style}
          disabled={buttonTwoProps.disabled}
          data-testid={buttonTwoProps.dataTestId}
        >
          <span className='button-text'>{buttonTwoProps.children}</span>
        </Button>
      )}
      <Button
        className={buttonOneProps.className}
        ref={buttonOneProps.ref as React.RefObject<any | null>}
        name={buttonOneProps.name}
        loading={buttonOneProps.loading}
        style={buttonOneProps.style}
        onClick={buttonOneProps.onClick}
        type={buttonOneProps.type}
        theme={buttonOneProps.theme}
        disabled={buttonOneProps.disabled}
        data-testid={buttonOneProps.dataTestId}
      >
        <span className='button-text'>{buttonOneProps.children}</span>
      </Button>
    </div>
  );
};

export default ButtonGroup;
